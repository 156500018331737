import React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <section className="container mx-auto text-center text-6xl px-4 md:px-10 py-12 md:py-24">
      404
    </section>
  </Layout>
)

export default NotFoundPage
